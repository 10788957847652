.catalogo__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.catalogo__item{
    text-align: center;
    background: transparent;
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    transition: var(--transition);
}

.catalogo__item:hover{
    border-color: var(--color-primary-variant);
    background: var(--color-bg-variant);
}

.catalogo__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.catalogo__item h3{
    margin: 1.2rem 0 2rem;
    color: var(--color-primary);
}

.catalogo__item p{
    padding-bottom: 1rem;
    color: var(--color-dark);
}

.catalogo__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}

/*========= MEDIA QUERIES (MEDIUM) =================*/
@media screen and (max-width: 1024px) {
    .catalogo__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
 }
 
 /*========= MEDIA QUERIES (SMALL) =================*/
 @media screen and (max-width: 600px) {
    .catalogo__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
 }