.container.contact__container{
    width: 58%;
    display: block;
    grid-template-columns: 1fr;
    gap: 12%;
}

.contact__options{
    display: flex;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.2rem;
    margin-bottom: 2rem;
}

.contact__option{
    width: 100%;
    color: var(--color-primary);
    padding: 1.2rem 0;
    text-align: center;
    border: 1px solid var(--color-primary);
    border-radius: 1.2rem;
    transition: var(--transition);

}
.contact__option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: .5rem;
}

.contact__option h5{
    color: var(--color-light);
}

/* ===========FORM==============*/
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: .5rem;
    border: 2px solid var(--color-primary);
    resize: none;
    color: var(--color-primary);
}

/*========= MEDIA QUERIES (MEDIUM) =================*/
@media screen and (max-width: 1024px) {
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
 
 /*========= MEDIA QUERIES (SMALL) =================*/
 @media screen and (max-width: 600px) {
     .container.contact__container{
         width: var(--container-width-sm);
     }
     .contact__options{
         display: block;
     }

     .contact__option{
         margin: 1rem 0;
     }
    
 }
