header{
    height: 100vh;
}

.header__container{
    text-align: center;
    width: 100%;
    height: 100%;

    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url('../../assets/homeImage.jpg');
    background-size: cover;
    justify-content: center;
    display: flex;
    align-items: center;
}


/*=========  CTA  ====================*/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*=========  HEADER SOCIALS  ====================*/
.header__socials{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
    margin-top: 3rem;
    font-size: 2rem;
}

.header__socials a{
    color: var(--color-primary-variant);
}
.header__socials a:hover{
    color: var(--color-primary);
}



/*=========  SCROLL DOWN  ====================*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: .9rem;
}

/*========= MEDIA QUERIES (MEDIUM) =================*/


/*========= MEDIA QUERIES (SMALL) =================*/
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }
    
    .scroll__down{
        display: none;
    }
}