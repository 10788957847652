.about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}


.about__me-image{
    
    border-radius: 2rem;
    overflow: hidden;
}


.about__content{
    color: var(--color-dark);
    text-align: center;
}
.about__content h2{
    color: var(--color-primary);
}

/*========= MEDIA QUERIES (MEDIUM) =================*/
@media screen and (max-width: 1024px) {
   .about__container{
       grid-template-columns: 1fr;
       gap: 0;
   }
   .about__me{
       width: 50%;
       margin: 2rem auto 4rem;
   }

   .about__content p{
       font-size: 1.2rem;
       margin: 1rem 0 1.5rem;
   }
}

/*========= MEDIA QUERIES (SMALL) =================*/
@media screen and (max-width: 600px) {
    .about__me{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards{
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content{
        text-align: center;
    }

    .about__content p{
        margin: 1.5rem 0;
    }
}