#proveemos{    
    padding-top: .5rem;
}

.proveemos__container{
    display: flex;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.line{
    border-radius: 2.1rem;
    height: fit-content;
    display: flex;
    align-items: center;
}


/*========= MEDIA QUERIES (MEDIUM) =================*/
@media screen and (max-width: 1024px) {
    .proveemos__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .line{
        height: auto;
    }
}
 
 /*========= MEDIA QUERIES (SMALL) =================*/
 @media screen and (max-width: 600px) {
     .proveemos__container{
         display: block;
         justify-content: center;
         
     }
     .line{
         justify-content: center;
     }
}